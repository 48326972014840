import { get } from 'lodash'

type GAEventParams = {
  category: string
  action: string
  label?: string
  value?: string
}

type FBEventParams = {
  event: string
  value?: string
  currency?: string
}

export type LogEventParams = {
  ga?: GAEventParams
  fb?: FBEventParams
}

type LogPageviewParams = {
  customPage: string
}

type GTMParams =
  | ({
      event: string
      user_id?: string
    } & Partial<LogEventParams> &
      Partial<LogPageviewParams>)
  | EcommerceParams

export type GaEcommerce = null | {
  transaction_id?: string
  currency?: string
  value?: number
  coupon?: string
  payment_type?: string
  items?: {
    item_id: string
    item_name?: string
    coupon?: string
    currency?: string
    discount?: number
    price?: number
    quantity?: number
  }[]
}

type EcommerceParams = {
  ecommerce_type: 'view_item' | 'add_to_cart' | 'purchase' | ''
  ecommerce: null | GaEcommerce
}
type CustomDimensions = {
  customDM1?: unknown
  customDM2?: unknown
  dimension1?: unknown
  dimension2?: unknown
}

const initialCustomDimensions = {
  customDM1: undefined,
  customDM2: undefined,
  dimension1: undefined,
  dimension2: undefined,
} as const

const variableNames = {
  pageView: {
    page: 'customPage',
  },
  event: {
    category: 'eventCategory',
    action: 'eventAction',
    label: 'eventLabel',
    value: 'eventValue',
  },
}

function isDataInvalid(data: GTMParams): boolean {
  return typeof get(data, 'event') !== 'string'
}

export function gtmPush(data: GTMParams): void {
  const dataLayer = window.dataLayer

  if (typeof dataLayer !== 'undefined' && !isDataInvalid(data)) {
    dataLayer.push(data)
  }
}

export function logPageview(customDimensions: CustomDimensions = {}): void {
  const { pageView } = variableNames

  const customData = {
    ...initialCustomDimensions,
    [pageView.page]: `${window.location.pathname}${window.location.search}`,
    ...customDimensions,
  }

  gtmPush({
    event: 'trackPageview',
    ...customData,
  })
}

export function logEvent(
  { ga, fb }: LogEventParams,
  customDimensions?: typeof initialCustomDimensions,
): void {
  const customData = {
    ...initialCustomDimensions,
    ...(customDimensions || {}),
  }

  gtmPush({
    event: 'Track Custom Event',
    ga,
    fb,
    ...customData,
  })
}

export function logEcommerce(
  name: 'view_item' | 'add_to_cart' | 'purchase' | '',
  ecommerce: EcommerceParams['ecommerce'],
): void {
  gtmPush({
    event: 'ecommerce',
    ecommerce_type: name,
    ecommerce,
  })
}
