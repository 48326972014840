import React from 'react'
import styled from '@emotion/styled'
import { Button as ChakraButton, As, CSSObject } from '@chakra-ui/react'
import Image, { ImageProps } from 'next/image'

import { mq } from '@/lib/responsive'

export const Container = styled.div(() => ({
  margin: '0 auto',
  maxWidth: '349px',

  [mq('sm')]: {
    maxWidth: 728,
  },

  [mq('md+')]: {
    maxWidth: 1113,
  },
}))

export const H1 = styled.h1(() => ({
  fontSize: '40px',

  [mq('sm')]: {
    fontSize: '48px',
  },

  [mq('lg')]: {
    fontSize: '56px',
    lineHeight: '74px',
  },
}))

export const H2 = styled.h2(() => ({
  fontSize: '40px',
  lineHeight: '60px',
  textAlign: 'center',

  [mq('lg')]: {
    fontSize: '52px',
    lineHeight: '72px',
  },
}))

export const P = styled.p(() => ({
  fontSize: '20px',
  fontWeight: 300,

  [mq('sm')]: {
    fontSize: '24px',
  },
}))

export const Colors = {
  PRIMARY_DEFAULT: '#ff7315',
  PRIMARY_LIGHT: '#ffb27e',
  WHITE: '#ffffff',
  JAGUAR_BLACK: '#2b2b2b',
  JUMBO_GRAY: '#888888',
  MAGNESIUM_GREY: '#b2b2b2',
  IRON_GREY: '#cccccc',
  WHISPER_GREY: '#e6e6e6',
  SMOKE_GREY: '#f6f6f6',
}

type ExternalLinkProps = {
  children: React.ReactNode
  href: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const ExternalLink = ({
  children,
  className,
  href,
  onClick,
}: ExternalLinkProps): React.ReactElement => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      onClick={onClick}
      css={{
        cursor: 'pointer',
        '&:hover': { color: 'inherit', textDecoration: 'underline' },
      }}
    >
      {children}
    </a>
  )
}

type ButtonProps = {
  children: React.ReactNode
  className?: string
  as: As
  sx: CSSObject
  css: CSSObject
}

export const Button = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, className, as = 'a', sx, css, ...rest } = props

    const style = {
      height: '72px',
      borderRadius: '8px',
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY_DEFAULT,
      padding: '24px 22px',
      lineHeight: 1,
      fontSize: '24px',
      outline: 'none',
      cursor: 'pointer',
      fontWeight: 500,
      ...sx,
      ...css,
    }

    return (
      <ChakraButton
        as={as !== 'button' ? as : undefined}
        sx={style}
        _hover={style}
        _focus={style}
        _active={style}
        className={className}
        ref={ref}
        {...rest}
      >
        {children}
      </ChakraButton>
    )
  },
)

export const Background = (
  props: Pick<ImageProps, 'src' | 'objectFit' | 'alt' | 'priority'>,
): React.ReactElement => (
  <Image
    layout="fill"
    objectFit="cover"
    css={{ pointerEvents: 'none', zIndex: -1 }}
    {...props}
  />
)
